import { createTheme } from "@material-ui/core/styles";

export default createTheme({
  overrides: {
    MuiButtonBase: {
      root: {
        "&.MuiTab-root": {
          outline: "none",
        },
      },
    },
    MuiTabs: {
      root: {
        "&.landing-page-search-tabs": {
          "& .MuiTab-root.Mui-selected": {
            fontWeight: 900,
          },
        },
      },
      indicator: {
        backgroundColor: "white",
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow:
          "0px 2px 1px -1px rgb(80 80 80 / 20%), 0px 1px 1px 0px rgb(80 80 80 / 14%), 0px 1px 3px 0px rgb(80 80 80 / 12%)",
      },
    },
    MuiTypography: {
      root: {
        fontSize: "13px",
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%)",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "3px",
      },
    },
    MuiBadge: {
      colorPrimary: {
        backgroundColor: "#E2004C",
      },
      badge: {
        padding: "0 3px",
        height: "15px",
        minWidth: "15px",
      },
    },
    MuiChip: {
      root: {
        flexDirection: "row-reverse",
        borderRadius: 4,
        backgroundColor: "#E5E7EC",
        height: 28,
      },
      deleteIcon: {
        margin: "0 0 0 5px",
      },
      label: {
        paddingLeft: 6,
      },
    },
  },
  typography: {},
});
