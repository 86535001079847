import * as React from "react";
import { makeStyles, Button, CircularProgress } from "@material-ui/core";

interface IPrimaryButtonProps {
  id?: string;
  loading?: boolean;
  children: string;
  onClick?: (event: any) => void;
  disabled?: boolean;
  style?: any;
}

const useStyles = makeStyles({
  primaryButton: {
    backgroundColor: "#221E54",
    color: "white",
    width: "100%",
    "&:hover": {
      backgroundColor: "#221E54",
    },
    height: "100%",
    fontSize: "13px",
  },
});

export default function PrimaryButton({
  id,
  loading,
  children,
  onClick,
  disabled,
  style,
}: IPrimaryButtonProps): React.ReactElement {
  const classes = useStyles();
  return (
    <Button
      id={id}
      color="primary"
      type="button"
      classes={{ root: classes.primaryButton }}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {!loading && <>{children}</>}
      {loading && <CircularProgress size={24} color="inherit" />}
    </Button>
  );
}
