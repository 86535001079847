import * as React from "react";
import { useParams } from "react-router-dom";
import jwt from "jwt-decode";
import { authVerify } from "../../api/vendor-core/v1";
import VerifySuccess from "./VerifySuccess";
import VerifyError from "./VerifyError";

export default function Verify(): React.ReactElement {
  const { access_token } = useParams<{ access_token: string }>();
  const [verifiedCompleted, setVerifiedCompleted] = React.useState<any>(null);
  const { callback } = jwt<any>(access_token);
  const [serverValidationErrorKey, setServerValidationErrorKey] =
    React.useState("");
  React.useEffect(() => {
    const verifyUser = async () => {
      try {
        await authVerify({ token: access_token });
        setVerifiedCompleted(true);
      } catch (e: any) {
        if (e?.response?.obj.detail) {
          setServerValidationErrorKey(e?.response?.obj.detail);
          console.log(e?.response?.obj.detail, "err");
        }
        setVerifiedCompleted(false);
      }
    };
    if (access_token) {
      verifyUser();
    }
  }, [access_token]);

  if (verifiedCompleted === null) {
    return <div />;
  }

  return verifiedCompleted ? (
    <VerifySuccess callback={callback} />
  ) : (
    <VerifyError serverValidationErrorKey={serverValidationErrorKey} />
  );
}
