import * as React from "react";
import { CSSProperties, ReactElement, ReactNode } from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  linkLabel: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

interface ILabelProps {
  children?: ReactNode;
  style?: CSSProperties;
  color?: string;
  size?: number;
  onClick: any;
}

export default function LinkLabel({
  children,
  style,
  color,
  size,
  onClick,
}: ILabelProps): ReactElement {
  const classes = useStyles();
  let styleObject = style || {};
  if (color) styleObject = { ...styleObject, color };
  if (size) styleObject = { ...styleObject, fontSize: `${size}rem` };

  return (
    <Typography
      className={classes.linkLabel}
      style={styleObject}
      onClick={onClick}
    >
      {children}
    </Typography>
  );
}
