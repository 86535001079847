/* eslint-disable */
import * as React from "react";

export default function useUpdateWindowHeight(): any {
  React.useLayoutEffect(() => {
    function updateSize() {
      if (window.parent) {
        window.parent.postMessage(
          { height: window.document.body.scrollHeight },
          "*"
        );
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
}
