/* eslint-disable */
import * as React from "react";
import { TextField } from "mui-rff";
import { useForm, useFormState } from "react-final-form";
import _get from "lodash/get";
import _isNil from "lodash/isNil";

interface IEmailInput {
  name: string;
  className?: string;
  InputProps?: any;
  placeholder?: string;
}

const isEmailInvalid = (value: string) => {
  const emailPattern =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return !emailPattern.test(value);
};

export default function EmailInput({
  name,
  InputProps,
  className,
  ...rest
}: IEmailInput): React.ReactElement {
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const formApi = useForm();
  const { errors, submitFailed, submitErrors } = useFormState();

  const currentFieldFormError = _get(errors, name);
  const submitError = _get(submitErrors, name);

  const handleChange = (e: any) => {
    formApi.change(name, e.target.value);
    if (!isEmailInvalid(e.target.value)) {
      setInvalidEmail(false);
    }
  };

  const validateEmail = (e: any) => {
    const email = e.target.value;
    if (!email || isEmailInvalid(email)) {
      setInvalidEmail(true);
    } else {
      setInvalidEmail(false);
    }
  };

  return (
    <TextField
      name={name}
      id="email-address"
      className={className}
      // @ts-ignore
      onChange={handleChange}
      onBlur={validateEmail}
      error={
        invalidEmail ||
        (!_isNil(currentFieldFormError) && submitFailed) ||
        submitError
      }
      helperText={invalidEmail ? "Невалиден е-маил" : ""}
      InputProps={InputProps}
      {...rest}
    />
  );
}
