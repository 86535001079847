import { useHistory } from "react-router-dom";

interface UseNavigation {
  navigateTo: (to: string) => void;
}
export default function useNavigation(): UseNavigation {
  const history = useHistory();

  const locale = window.location.href.split("/").pop();
  return {
    navigateTo: (to: string) => {
      history.push(`/${to}/${locale}`);
    },
  };
}
