import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import mk from "./translations/mk.json";
import en from "./translations/en.json";
import sq from "./translations/sq.json";

const locale = window.location.href.split("?")[0].split("/").pop();
const resources = {
  en,
  mk,
  sq,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: locale,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
