import { Palette } from "@material-ui/core/styles/createPalette";

// eslint-disable-next-line import/prefer-default-export
export const palette: Partial<Palette> = {
  primary: {
    main: "#221E54",
    light: "#386F98",
    dark: "",
    contrastText: "",
  },
  secondary: {
    main: "#00A9D8",
    light: "#EB1E5F",
    contrastText: "#fff",
    dark: "",
  },

  success: {
    main: "green",
    light: "#F0F5FF",
    contrastText: "#FCFDFE",
    dark: "green",
  },
  background: {
    default: "#FAFBFD",
    paper: "white",
  },
  grey: {
    50: "#F9FAFD",
    100: "#F9FAFD",
    200: "#F9FAFD",
    300: "#efefef",
    400: "#efefef",
    500: "#efefef",
    600: "#efefef",
    700: "#888888",
    800: "#888888",
    900: "#888888",
    A100: "#545467",
    A200: "#545467",
    A400: "#545467",
    A700: "#545467",
  },
};
