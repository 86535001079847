import React, { ReactElement, ReactNode, useMemo } from "react";
import { Form } from "react-final-form";
import { Box } from "@mui/material";
import { Button, makeStyles } from "@material-ui/core";
import { makeValidate, TextField } from "mui-rff";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import LinkLabel from "../components/LinkLabel";
import { codeParser } from "../utils/parsers";
import useNavigation from "../utils/navigation";
import useUpdateWindowHeight from "../utils/window";
import { ResetPasswordContactContext } from "../contexts/ResetPasswordContactContext";
import { getPasswordResetCode, validateResetCode } from "../api/vendor-core/v1";

const useStyles = makeStyles(() => ({
  input: {
    width: "300px",
    margin: "4px 0",
    minHeight: "50px",
  },
  icon: {
    color: "#309CD0",
  },
}));

export default function EnterCodeForm(): ReactElement {
  const classes = useStyles();
  useUpdateWindowHeight();
  const { t } = useTranslation();
  const { navigateTo } = useNavigation();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { resetPasswordData, setResetPasswordData } = React.useContext(
    ResetPasswordContactContext
  );

  // eslint-disable-next-line consistent-return
  const handleSubmitForm = async ({ code }: any) => {
    try {
      const response = await validateResetCode({
        otp: code,
        ...resetPasswordData,
      });
      if (response.ok) {
        const { access_token } = response.body;
        setResetPasswordData({
          ...resetPasswordData,
          access_token,
          sameOrigin: true,
        });
        navigateTo("reset-password");
      }
    } catch (e: any) {
      if (e?.response?.obj.detail) {
        // eslint-disable-next-line consistent-return
        return {
          code: t(`serverValidation.${e?.response?.obj?.detail}`),
        };
      }
    }
  };

  const handleResendClick = async () => {
    await getPasswordResetCode({ ...resetPasswordData });
  };

  const schema = useMemo(() => {
    return Yup.object().shape({
      code: Yup.string().matches(
        /^[0-9]{6}$/,
        t("forgotPasswordForm.validation.code")
      ),
    });
  }, [t]);
  const validate = makeValidate(schema);

  return (
    <Form
      validate={validate}
      onSubmit={handleSubmitForm}
      render={({ handleSubmit }): ReactNode => (
        <form onSubmit={handleSubmit}>
          <Box
            display="flex"
            style={{
              flexFlow: "column",
              alignItems: "center",
              marginLeft: "40px",
              marginRight: "40px",
              minWidth: "300px",
            }}
          >
            <div style={{ margin: "20px 0", width: "100%" }}>
              <h3>{t("forgotPasswordForm.enterCode.title")}</h3>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label style={{ color: "#7A7C83" }}>
                {resetPasswordData.email
                  ? t("forgotPasswordForm.enterCode.email")
                  : t("forgotPasswordForm.enterCode.phone")}
              </label>
              <LinkLabel
                style={{ color: "#309CD0", fontSize: "inherit" }}
                onClick={handleResendClick}
              >
                {t("forgotPasswordForm.enterCode.resend")}
              </LinkLabel>
            </div>
            <TextField
              name="code"
              className={classes.input}
              fieldProps={{ parse: codeParser }}
            />
            <Button
              color="primary"
              variant="outlined"
              style={{ marginTop: "20px", width: "300px" }}
              onClick={handleSubmit}
            >
              {t("forgotPasswordForm.buttons.nextStep")}
            </Button>
            <span
              style={{
                marginTop: "20px",
                cursor: "pointer",
                color: "#7A7C83",
                maxWidth: "300px",
                alignSelf: "start",
              }}
              role="button"
              onClick={() => navigateTo("login")}
              tabIndex={0}
            >
              {t("backToLogin")}
            </span>
          </Box>
        </form>
      )}
    />
  );
}
