import * as React from "react";
import { useParams } from "react-router-dom";
import jwt from "jwt-decode";
import { ResetPasswordContactContext } from "../contexts/ResetPasswordContactContext";
import useNavigation from "../utils/navigation";

export default function ForgotPasswordVerify(): React.ReactElement {
  const { access_token } = useParams<{ access_token: string }>();
  const { email } = jwt<any>(access_token);
  const { resetPasswordData, setResetPasswordData } = React.useContext<any>(
    ResetPasswordContactContext
  );
  const { navigateTo } = useNavigation();
  React.useEffect(() => {
    setResetPasswordData({ ...resetPasswordData, access_token, email });
    navigateTo("reset-password");
  }, [
    email,
    navigateTo,
    resetPasswordData,
    setResetPasswordData,
    access_token,
  ]);

  return <div />;
}
