import * as React from "react";

// eslint-disable-next-line import/prefer-default-export
export const ResetPasswordContactContext = React.createContext({});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function WithResetPasswordData({
  children,
}: any): React.ReactElement {
  const [resetPasswordData, setResetPasswordData] = React.useState({});

  return (
    <ResetPasswordContactContext.Provider
      value={{ resetPasswordData, setResetPasswordData }}
    >
      {children}
    </ResetPasswordContactContext.Provider>
  );
}
