import React, { useMemo, useCallback, useState } from "react";
import { Form } from "react-final-form";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { InputAdornment, makeStyles } from "@material-ui/core";
import { makeValidate, TextField } from "mui-rff";
import * as Yup from "yup";
import _capitalize from "lodash/capitalize";
import _isEqual from "lodash/isEqual";
import { useTranslation } from "react-i18next";

// icons
import userIcon from "../assets/icons/icon-blue-user.svg";
import passwordIcon from "../assets/icons/icon-blue-lock.svg";
import emailIcon from "../assets/icons/icon-blue-email.svg";

// Components
import PhoneInput from "../form/PhoneInput";
import PasswordInput from "../form/PasswordInput";
import PrimaryButton from "../components/PrimaryButton";
import EmailInput from "../form/EmailInput";

// Utils
import { useCommonValidation } from "../utils/validators";
import { phoneParser } from "../utils/parsers";
import { authRegister } from "../api/vendor-core/v1";
import useNavigation from "../utils/navigation";
import useUpdateWindowHeight from "../utils/window";

const useStyles = makeStyles({
  input: {
    margin: "4px 0",
    minHeight: "50px",
  },
  lightPrimaryButton: {
    color: "#309CD0",
    borderColor: "#309CD0",
  },
});

function trimDashesFromString(phoneNumber: string) {
  const phoneNumbers = phoneNumber.split("-");

  return `${phoneNumbers[0]}${phoneNumbers[1]}${phoneNumbers[2]}`;
}

export default function RegisterForm(): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const { locale } = useParams<any>();
  useUpdateWindowHeight();
  const { navigateTo } = useNavigation();
  const [loading, setLoading] = useState(false);

  const { alphanumeric, email, strongPassword } = useCommonValidation();

  const fields = [
    {
      name: "first_name",
      component: TextField,
      placeholder: t("registerForm.name"),
      icon: userIcon,
    },
    {
      name: "last_name",
      component: TextField,
      placeholder: t("registerForm.lastName"),
      icon: userIcon,
    },
    {
      name: "email",
      component: EmailInput,
      placeholder: t("registerForm.email"),
      icon: emailIcon,
    },
    {
      name: "phone",
      component: PhoneInput,
      placeholder: t("registerForm.phone"),
      fieldProps: {
        parse: phoneParser,
      },
    },
    {
      name: "password",
      component: PasswordInput,
      placeholder: t("registerForm.password"),
      icon: passwordIcon,
      InputProps: { type: "password" },
    },
  ];

  const handleRegister = useCallback(
    // eslint-disable-next-line consistent-return
    async (values) => {
      const { phoneNumber, phonePrefix, first_name, last_name, ...restValues } =
        values;
      const phoneNumberWithoutDashes = trimDashesFromString(phoneNumber);
      const mobile_phone = `${phonePrefix}${phoneNumberWithoutDashes}`;
      const data = {
        mobile_phone,
        first_name: _capitalize(first_name),
        last_name: _capitalize(last_name),
        ...restValues,
        site_id: "15e76909-5a75-41a5-b1a9-e82cb1283b09",
        locale,
      };
      try {
        setLoading(true);
        const response = await authRegister(data);
        if (response.ok) {
          setLoading(false);
          navigateTo("/login");
        } else {
          return { email: response };
        }
      } catch (e: any) {
        setLoading(false);
        if (e?.response?.obj.detail) {
          // eslint-disable-next-line consistent-return
          return {
            email: t(`serverValidation.${e?.response?.obj?.detail}`),
          };
        }
      }
    },
    [locale, navigateTo, t]
  );
  const schema = useMemo(() => {
    return Yup.object().shape({
      first_name: alphanumeric.required(t("registerForm.validation.name")),
      last_name: alphanumeric.required(t("registerForm.validation.lastName")),
      email: email.required(t("registerForm.validation.email")),
      phoneNumber: Yup.string().required(t("registerForm.validation.phone")),
      password: strongPassword.required(t("registerForm.validation.password")),
    });
  }, [alphanumeric, email, strongPassword, t]);
  const validate = makeValidate(schema);

  return (
    <Form
      validate={validate}
      initialValues={{ phonePrefix: "389" }}
      initialValuesEqual={_isEqual}
      onSubmit={handleRegister}
      render={({ handleSubmit }): React.ReactElement => (
        <form onSubmit={handleSubmit}>
          <div style={{ marginLeft: "40px", marginRight: "40px" }}>
            <Box
              display="flex"
              style={{ flexFlow: "column", marginTop: "20px" }}
            >
              {fields.map((field) => {
                return (
                  <field.component
                    key={field.name}
                    name={field.name}
                    className={classes.input}
                    placeholder={field.placeholder}
                    InputProps={{
                      ...field.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={field.icon} alt="" />
                        </InputAdornment>
                      ),
                    }}
                    fieldProps={field.fieldProps}
                  />
                );
              })}
              <Box>
                <PrimaryButton
                  onClick={handleSubmit}
                  loading={loading}
                  style={{ marginTop: "20px" }}
                >
                  {t("registerForm.buttons.register")}
                </PrimaryButton>
              </Box>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <span
                style={{
                  marginTop: "20px",
                  cursor: "pointer",
                  color: "#7A7C83",
                  maxWidth: "300px",
                }}
                role="button"
                onClick={() => navigateTo("login")}
                tabIndex={0}
              >
                {t("backToLogin")}
              </span>
            </Box>
          </div>
        </form>
      )}
    />
  );
}
