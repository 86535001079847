import * as React from "react";
import { TextField } from "mui-rff";
import { InputAdornment, makeStyles } from "@material-ui/core";
import passwordIcon from "../assets/icons/icon-blue-lock.svg";
import viewIcon from "../assets/icons/view.svg";
import hideIcon from "../assets/icons/hide.svg";

interface IPasswordInput {
  name: string;
  className?: string;
  InputProps?: any;
  placeholder?: string;
}

const useStyles = makeStyles({
  icon: {
    cursor: "pointer",
  },
});

export default function PasswordInput({
  name,
  InputProps,
  className,
  ...rest
}: IPasswordInput): React.ReactElement {
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);

  const togglePassword = React.useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const showIcon = showPassword ? viewIcon : hideIcon;
  const type = showPassword ? "text" : "password";

  return (
    <TextField
      {...rest}
      name={name}
      className={className}
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <img src={passwordIcon} alt="" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="start"
            onClick={togglePassword}
            className={classes.icon}
          >
            <img src={showIcon} alt="" />
          </InputAdornment>
        ),
        type,
      }}
    />
  );
}
