import createSwaggerClient from "../swaggerClient";

const vendorCoreSpecUrl = process.env.REACT_APP_API_VENDOR_CORE_OPEN_API;

const vendorCoreClient = createSwaggerClient(vendorCoreSpecUrl);

// eslint-disable-next-line import/prefer-default-export
export const makeVendorCoreClient = (): any => {
  return vendorCoreClient;
};
