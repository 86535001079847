import { useMemo } from "react";
import * as yup from "yup";

// eslint-disable-next-line import/prefer-default-export
export const useCommonValidation = (): any =>
  useMemo(() => {
    return {
      email: yup.string().required("Е-маил адресата е задолжителна"),
      alphanumeric: yup
        .string()
        .matches(
          /^[a-zA-Zа-шА-ШќЌљЉњЊжЖѓЃџЏјЈѕS]+$/,
          "Дозволени се само букви"
        ),
      strongPassword: yup
        .string()
        .matches(
          /(?=.*[a-zа-шљњѕшѓжзџќч])(?=.*[A-ZА-ШЉЊSЃЖЗЏЌЧ])(?=.*[0-9])(?=.*[^A-ZА-ШЉЊSЃЖЗЏЌЧa-zа-шљњѕшѓжзџќч0-9])(?=.{8,})/,
          "Лозинката треба да биде минимум 8 карактери, содржи барем една голема буква, бројка и специјален знак"
        ),
    };
  }, []);
