// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Swagger from "swagger-client";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function swaggerClient(specUrl: any, swaggerOpts?: any) {
  Swagger.http.withCredentials = true;
  return Swagger(specUrl, swaggerOpts)
    .catch((...args: any) => {
      // eslint-disable-next-line no-console
      console.error("Error happened while creating a swagger-client", args);
      return Promise.reject(args);
    })
    .then((client: any) => client);
}
